export interface ChecklistItem {
    id: number;
    name: string;
    completed: boolean;
  }
  
  export interface ChecklistState {
    checklist: ChecklistItem[];
  }
  
  export const initialChecklistState: ChecklistState = {
    checklist: [],
  };
  
  export type ChecklistAction =
    | { type: 'SET_CHECKLIST'; payload: ChecklistItem[] }
    | { type: 'ADD_CHECKLIST_ITEM'; payload: ChecklistItem }
    | { type: 'REMOVE_CHECKLIST_ITEM'; payload: number }; // Remove by ID
  
  export const TicketChecklistReducer = (
    state: ChecklistState = initialChecklistState,
    action: ChecklistAction
  ): ChecklistState => {
    switch (action.type) {
      case 'SET_CHECKLIST':
        return { ...state, checklist: action.payload };
      case 'ADD_CHECKLIST_ITEM':
        return { ...state, checklist: [...state.checklist, action.payload] };
      case 'REMOVE_CHECKLIST_ITEM':
        return {
          ...state,
          checklist: state.checklist.filter((item) => item.id !== action.payload),
        };
      default:
        return state;
    }
  };
  