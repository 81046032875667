import {
  EnumTicketStatus,
  EnumSubButtonInterface,
  IScheduleViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import {
  ITicketPostCommunicationData,
  ITicketPostBillingViewModel,
  ITicketPostViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import dayjs from 'dayjs';

export const TicketPostState: ITicketPostCommunicationData = {
  id: null,
  isEMailSent: false,
  ticketPosts: [],
  ticketPost: {} as unknown as ITicketPostViewModel,
  status: EnumTicketStatus.New,
  scheduleTime: {
    id: '00000000-0000-0000-0000-000000000000',
    deliveryDate: dayjs(new Date()),
    scheduleType: 0,
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  ticket: {} as unknown as ITicketViewModel,
  workRole: null,
  workRoles: [] as IWorkRoleViewModel[],
  isInHouse: true,
  isBillable: true,
  isBilled: false,
  overTime: false,
  internal: false,
  totalTime: '00:00',
  totalValue: 0,
  isTotalValueOpen: false,
  isTotalTimeOpen: false,
  startDate: dayjs(new Date()).add(-15, 'minute'),
  endDate: dayjs(new Date()),
  comment: '',
  attachments: null,
  selectedDocuments: null,
  direction: 0,
  ticketPostBilling: null,
  rates: [],
  isShowOverTime: false,
  isEditable: true,
  isEmailAllowed: true,
  page: EnumSubButtonInterface.Communication,
  startTime: dayjs(new Date()),
  endTime: dayjs(new Date()),
  startDeduction: null,
  endDeduction: null,
  receiverId: null,
};

// Definer handlingstyper
export type Action =
  | { type: 'IS_SEND_MAIL'; payload: boolean }
  | { type: 'SET_TICKET_POSTS'; payload: ITicketPostViewModel[] }
  | { type: 'SET_TICKET_POST'; payload: ITicketPostViewModel }
  | { type: 'REMOVE_TICKET_POST'; payload: ITicketPostCommunicationData }
  | { type: 'SET_STATUS'; payload: EnumTicketStatus }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'SET_TICKET'; payload: ITicketViewModel }
  | { type: 'SET_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'SET_WORK_ROLES'; payload: IWorkRoleViewModel[] }
  | { type: 'SET_IS_IN_HOUSE'; payload: boolean }
  | { type: 'SET_BILLABLE'; payload: boolean }
  | { type: 'SET_OVERTIME'; payload: boolean }
  | { type: 'SET_INTERNAL'; payload: boolean }
  | { type: 'SET_TOTAL_TIME'; payload: string }
  | { type: 'SET_TOTAL_TIME_OPEN'; payload: boolean }
  | { type: 'SET_TOTAL_VALUE'; payload: number }
  | { type: 'SET_TOTAL_VALUE_OPEN'; payload: boolean }
  | { type: 'SET_START_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_END_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'CHANGE_ATTACHMENTS'; payload: any }
  | { type: 'SET_SELECTED_DOCUMENTS'; payload: any }
  | { type: 'CHANGE_DIRECTION'; payload: number }
  | { type: 'SET_TICKET_POST_BILLING'; payload: ITicketPostBillingViewModel }
  | { type: 'SET_RATES'; payload: IRateViewModel[] }
  | { type: 'SET_SHOW_OVERTIME'; payload: boolean }
  | { type: 'EDIT_COMMENT'; payload: string }
  | { type: 'CHANGE_STATUS'; payload: any }
  | { type: 'SET_IS_EDITABLE'; payload: boolean }
  | { type: 'SET_IS_EMAIL_ALLOWED'; payload: boolean }
  | { type: 'SET_PAGE'; payload: EnumSubButtonInterface };

// Reducer-funksjonen
export const TicketPostReducer = (
  state: ITicketPostCommunicationData = TicketPostState,
  action: Action
): ITicketPostCommunicationData => {
  switch (action.type) {
    case 'SET_IS_EMAIL_ALLOWED':
      return { ...state, isEmailAllowed: action.payload };
    case 'IS_SEND_MAIL':
      return { ...state, isEMailSent: action.payload };
    case 'SET_TICKET_POSTS':
      return { ...state, ticketPosts: action.payload };
    case 'SET_TICKET_POST':
      return {
        ...state,
        ticketPost: action.payload,
        status: action.payload.status,
        startDate: dayjs(action.payload.startTime),
        endDate: dayjs(action.payload.endTime),
        comment: action.payload.message,
        attachments: action.payload.attachments,
        direction: action.payload.direction,
        isBillable: action.payload.billable,
        overTime: action.payload.overtime,
        internal: action.payload.internal,
        isInHouse: action.payload.isInHouse ?? true,
        isEditable: false,
        ticketPostBilling: action.payload.ticketPostBilling,
      };
    case 'REMOVE_TICKET_POST':
      return {
        ...state,
        ticketPost: {} as ITicketPostViewModel,
        status: EnumTicketStatus.New,
        startDate: dayjs().add(-15, 'minute'),
        endDate: dayjs(),
        comment: '',
        attachments: null,
        direction: 0,
        isBillable: true,
        overTime: false,
        internal: false,
        isInHouse: true,
        isEditable: true,
        isEMailSent: false,
      };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_SCHEDULE_TIME':
      return { ...state, scheduleTime: action.payload };
    case 'SET_TICKET':
      return { ...state, ticket: action.payload };
    case 'SET_WORK_ROLE':
      return { ...state, workRole: action.payload };
    case 'SET_WORK_ROLES':
      return { ...state, workRoles: action.payload };
    case 'SET_IS_IN_HOUSE':
      return { ...state, isInHouse: action.payload };
    case 'SET_BILLABLE':
      return { ...state, isBillable: action.payload };
    case 'SET_OVERTIME':
      return { ...state, overTime: action.payload };
    case 'SET_INTERNAL':
      return {
        ...state,
        internal: action.payload,
        isBillable: false,
        isEMailSent: false,
      };
    case 'SET_TOTAL_TIME':
      return { ...state, totalTime: action.payload };
    case 'SET_TOTAL_TIME_OPEN':
      return { ...state, isTotalTimeOpen: action.payload };
    case 'SET_TOTAL_VALUE':
      return { ...state, totalValue: action.payload };
    case 'SET_TOTAL_VALUE_OPEN':
      return { ...state, isTotalValueOpen: action.payload };
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload };
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload };
    case 'SET_COMMENT':
      return { ...state, comment: action.payload };
    case 'CHANGE_ATTACHMENTS':
      return { ...state, attachments: action.payload };
    case 'SET_SELECTED_DOCUMENTS':
      return { ...state, selectedDocuments: action.payload };
    case 'CHANGE_DIRECTION':
      return { ...state, direction: action.payload };
    case 'SET_TICKET_POST_BILLING':
      const newTotalValue =
        action.payload.nightBillableValue +
        action.payload.workBillableValue +
        action.payload.midDayBillableValue +
        action.payload.eveningBillableValue;
      return {
        ...state,
        ticketPostBilling: action.payload,
        totalValue: newTotalValue,
      };
    case 'SET_RATES':
      return { ...state, rates: action.payload };
    case 'SET_SHOW_OVERTIME':
      return { ...state, isShowOverTime: action.payload };
    case 'EDIT_COMMENT':
      if (state.status === EnumTicketStatus.New) {
        return {
          ...state,
          comment: state.comment + action.payload,
          status: EnumTicketStatus.InProgress,
        };
      } else {
        return { ...state, comment: action.payload };
      }
    case 'CHANGE_STATUS':
      return { ...state, status: action.payload };
    case 'SET_IS_EDITABLE':
      return { ...state, isEditable: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    default:
      return state;
  }
};
