import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
// import { useForm } from "react-hook-form";
//@ts-ignore
import GetPriorityColor from 'functions/GetPriorityColor';
import EditIcon from '@mui/icons-material/Edit';
import useWindowsDimension from 'functions/useWindowsDimension';
// import { FormSelect } from "components/form";
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import {
  Button,
  ButtonGroup,
  Grid,
  List,
  Paper,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  ListItem,
} from '@mui/material';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost, usePut } from 'http/useInnovit';
//@ts-ignore
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
// import { SingleContactView } from "components/ui/SingleContactView";
import _ from 'lodash';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import {
  EnumAddressType,
  EnumBillingType,
  EnumBoard,
  EnumJobType,
  EnumObjectType,
  EnumPlacement,
  EnumPriority,
  EnumScheduleType,
  EnumSupportBilling,
  EnumTicketStatus,
  IAddressViewModel,
  IDeliveryAddressViewModel,
  IMobileAnswersViewModel,
  IScheduleViewModel,
  addressListData,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import {
  IEmployeeViewModel,
  IEmployeeViewModelWithMissingFields,
  IEmployeeWithCompanyViewModel,
  ITicketCompanyViewModel,
  ITicketDetailsInitialState,
  ITicketUpdateWithProductViewModel,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';
import dayjs from 'dayjs';
import { IAutoAnswersViewModel } from 'pages/AutoAnswersConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { FormBox } from 'components/form';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { SingleTicketContactProduct } from 'components/ui/SingleTicketContactProduct';
import { SingleAutoAnswerView } from 'components/ui/SingleAutoAnswerView';
import { DeliveryAddressView } from 'components/ui/DeliveryAddressView';
import {
  ConvertProductToCompanyProduct,
  ConvertStorageProductToCompanyProduct,
} from 'functions/Convert';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { useGetObjectList } from 'functions/useGetObjectList';
import userAtom from 'data/atoms/userAtom';
import { GenerateAddressList } from 'functions/Converters';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import { useTranslation } from 'react-i18next';
import { ProductCardView } from 'components/ui/ProductCardView';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { SingleTicketEmployeeView } from 'components/ui/SingleTicketEmployeeView';
import { SingleTicketCompanyView } from 'components/ui/SingleTicketCompanyView';

let initialState: ITicketDetailsInitialState = {
  id: 'new',
  ticketNo: 'new',
  board: EnumBoard.Support,
  priority: EnumPriority.NORMAL,
  status: EnumTicketStatus.New,
  companyId: 'new',
  company: {} as unknown as ITicketCompanyViewModel,
  contactId: 'new',
  contact: {} as unknown as IEmployeeViewModel,
  creatorId: 'new',
  creator: {} as unknown as IEmployeeViewModel,
  responsibleId: null,
  responsible: {} as unknown as IEmployeeViewModel,
  shippingCost: 0,
  deliveryAddress: {} as IDeliveryAddressViewModel,
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: dayjs(new Date()),
    startDate: dayjs(new Date()),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  },
  jobType: EnumJobType.Support,
  supportType: {} as IMobileAnswersViewModel,
  errorDescriptionId: '',
  errorDescription: {} as IMobileAnswersViewModel,
  companyProductWithErrorId: '',
  companyProductWithError: {} as ICompanyProductViewModel,
  technicians: null,
  comment: '',
  ccEmail: '',
  fixedHourlyRate: 0,
  fixedTime: 0,
  fixedPrice: 0,
  maxTime: 0,
  maxPrice: 0,
  productBilling: true,
  updated: dayjs(new Date()),
  billing: EnumBillingType.SystemPeriod,
  title: '',
  companyProducts: [] as ICompanyProductViewModel[],
  techniciansId: [] as string[],
  addressList: addressListData,
  storageProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
  supportBilling: EnumSupportBilling.BillOnCompletion,
};

export const ConvertStateToTicketUpdateWithProductViewModel = (
  state: ITicketDetailsInitialState,
  user: any
) => {
  let obj: ITicketUpdateWithProductViewModel = {
    id: state.id,
    board: state.board,
    ticketNo: state.ticketNo,
    priority: state.priority,
    status: state.status,
    deliveryDate: state.scheduleTime.deliveryDate,
    scheduled: state.scheduleTime.startDate,
    ETCHours: state.scheduleTime.estimatedTime.hours,
    ETCMinutes: state.scheduleTime.estimatedTime.minutes,
    jobType: state.jobType,
    supportTypeId: state.supportType.id,
    errorDescriptionId: state.errorDescriptionId ?? null,
    companyProductWithErrorId: state.companyProductWithErrorId ?? null,
    companyId: state.companyId,
    contactId: state.contactId,
    creatorId: user.id,
    responsibleId: state.responsibleId ?? null,
    shippingCost: state.shippingCost,
    deliveryStreet: state.deliveryAddress.street || '',
    deliveryPostalCode: String(state.deliveryAddress.postalCode) || '',
    deliveryArea: state.deliveryAddress.area || '',
    deliveryCity: state.deliveryAddress.city || '',
    deliveryCountry: state.deliveryAddress.country || '',
    deliveryPostbox: state.deliveryAddress.postBox || '',
    attention: state.deliveryAddress.attention || '',
    technicians: state.technicians,
    comment: state.comment,
    ccEmail: state.ccEmail,
    fixedHourlyRate: state.fixedHourlyRate,
    fixedTime: state.fixedTime,
    fixedPrice: state.fixedPrice,
    maxTime: state.maxTime,
    maxPrice: state.maxPrice,
    productBilling: state.productBilling,
    billing: state.billing,
    title: state.title,
    companyProducts: state.companyProducts,
    storageProducts: state.storageProducts,
    productCatalog: state.productCatalog,
    unassignedCompanyProducts: state.unassignedCompanyProducts,
    supportBilling: EnumSupportBilling.BillOnCompletion,
  };

  return obj;
};

type Action =
  | { type: 'RESET_STATE' }
  | { type: 'EDIT_COMMENT'; payload: any }
  | { type: 'SET_TICKET'; payload: ITicketViewModel }
  | { type: 'SET_BOARD'; payload: any }
  | { type: 'SET_JOB_TYPE'; payload: any }
  | { type: 'UPDATE_ADDRESS_LIST'; payload: IDeliveryAddressViewModel[] }
  | { type: 'SET_CONTACT'; payload: IEmployeeWithCompanyViewModel }
  | { type: 'SET_COMPANY'; payload: any }
  | { type: 'SET_PRIORITY'; payload: EnumPriority }
  | { type: 'SET_AUTO_ANSWER'; payload: any }
  | { type: 'SET_AUTO_ANSWERS'; payload: any }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | {
      type: 'SET_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'SET_COMPANY_PRODUCTS';
      payload: ICompanyProductViewModel[];
    }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'ADD_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'SET_DIRTY';
      payload: boolean;
    }
  | { type: 'SET_DELIVERY_ADDRESS'; payload: IDeliveryAddressViewModel }
  | {
      type: 'UPDATE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'SET_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'SET_ISSUE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'CHANGE_PRIORITY'; priority: number }
  | { type: 'CHANGE_BOARD'; payload: number }
  | { type: 'CHANGE_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'CHANGE_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'CHANGE_TECHNICIAN'; payload: IEmployeeViewModel[] }
  | { type: 'SET_IS_DIRTY'; payload: boolean }
  | { type: 'CLEAR_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'EDIT_TITLE'; payload: string }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel };

const reducer = (
  state: ITicketDetailsInitialState,
  action: Action
): ITicketDetailsInitialState => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      //console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      // console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'RESET_STATE':
      return initialState;
    case 'ADD_STORAGE_PRODUCT':
      // console.log('ADD_STORAGE_PRODUCT', action?.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action?.payload],
      };

    case 'UPDATE_STORAGE_PRODUCT':
      return {
        ...state,
        storageProducts: state.storageProducts.map((product) =>
          product.id === action.payload.id
            ? { ...product, ...action.payload }
            : product
        ),
      };
    case 'REMOVE_STORAGE_PRODUCT':
      // console.log('REMOVE_STORAGE_PRODUCT', action?.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product: any) => product.id !== action?.payload.id
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action?.payload],
      };
    case 'UPDATE_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product: any) =>
          product.id === action?.payload.id ? action?.payload : product
        ),
      };
    case 'ADD_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action?.payload,
        ],
      };
    case 'REMOVE_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product: any) => product.id !== action?.payload.id
        ),
      };
    case 'SET_TICKET':
      console.log('SET_TICKET payload', action?.payload);
      let deliveryDate =
        action.payload?.deliveryDate !== null
          ? action.payload.deliveryDate
          : dayjs(new Date());
      let startDate =
        action.payload?.deliveryDate !== null
          ? action.payload.deliveryDate
          : dayjs(new Date());

      let schedule = {
        id: '',
        scheduleType: action.payload?.deliveryDate
          ? EnumScheduleType.Reminder
          : EnumScheduleType.None,
        deliveryDate: deliveryDate,
        startDate: startDate,
        estimatedTime: {
          hours: action.payload?.ETCHours || 0,
          minutes: action.payload?.ETCMinutes || 0,
        },
      };

      let address = {
        id: null,
        addressId: null,
        addressType: EnumAddressType.custom,
        street: action.payload.deliveryStreet,
        postalCode: action.payload.deliveryPostalCode,
        area: action.payload.deliveryArea,
        city: action.payload.deliveryCity,
        country: action.payload.deliveryCountry,
        postBox: null,
        attention: action.payload.attention,
      };

      let technicians = null;
      return {
        ...state,
        id: action.payload.id,
        ticketNo: action.payload.ticketNo,
        board: action.payload.board,
        priority: action.payload.priority,
        status: action.payload.status,
        companyId: action.payload.company.id,
        company: action.payload.company,
        contactId: action.payload.contact.id,
        contact: action.payload.contact,
        creatorId: action.payload.creator.id,
        creator: action.payload.creator,
        responsibleId: action.payload.responsible?.id,
        responsible: action.payload.responsible,
        shippingCost: action.payload.shippingCost,
        deliveryAddress: address,
        scheduleTime: schedule,
        jobType: action.payload.jobType,
        supportType: action.payload.supportType,
        errorDescriptionId: action.payload?.errorDescription?.id ?? null,
        errorDescription: action.payload.errorDescription,
        companyProductWithErrorId:
          action.payload?.companyProductWithError?.id ?? null,
        companyProductWithError: action.payload.companyProductWithError,
        technicians: action.payload.technicians,
        comment: action.payload.comment,
        fixedHourlyRate: action.payload.fixedHourlyRate,
        fixedTime: action.payload.fixedTime,
        fixedPrice: action.payload.fixedPrice,
        maxTime: action.payload.maxTime,
        maxPrice: action.payload.maxPrice,
        productBilling: action.payload.productBilling,
        updated: action.payload.updated,
        billing: action.payload.billing,
        title: action.payload.title,
        techniciansId: technicians,
        isDirty: false,
      };
    case 'SET_BOARD': {
      return {
        ...state,
        board: action?.payload,
      };
    }
    case 'SET_JOB_TYPE': {
      return {
        ...state,
        jobType: action?.payload,
      };
    }

    case 'SET_CONTACT': {
      console.log('SET_CONTACT', action?.payload);
      return {
        ...state,
        contact: action?.payload,
        contactId: action?.payload.id,
      };
    }
    case 'SET_COMPANY':
      return {
        ...state,
        company: action?.payload,
        companyId: action?.payload.id,
      };
    case 'SET_PRIORITY': {
      return {
        ...state,
        priority: action?.payload,
      };
    }
    case 'SET_AUTO_ANSWER': {
      return {
        ...state,
        supportType: action.payload,
      };
    }
    case 'SET_COMPANY_PRODUCTS': {
      return {
        ...state,
        companyProducts: action?.payload,
      };
    }
    case 'SET_COMPANY_PRODUCT': {
      return {
        ...state,
        companyProductWithError: action?.payload,
        companyProductWithErrorId: action?.payload.id,
      };
    }
    case 'SET_RESPONSIBLE': {
      return {
        ...state,
        responsible: action?.payload,
        responsibleId: action?.payload.id,
      };
    }
    case 'SET_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: {
          ...action?.payload,
        },
      };

    case 'EDIT_COMMENT': {
      return {
        ...state,
        comment: action?.payload,
      };
    }

    case 'UPDATE_ADDRESS_LIST':
      return {
        ...state,
        addressList: {
          ...action?.payload,
        },
      };
    case 'CHANGE_PRIORITY':
      return {
        ...state,
        priority: action.priority,
        isDirty: true,
      };
    case 'CHANGE_BOARD':
      return {
        ...state,
        board: action.payload,
        isDirty: true,
      };
    case 'CHANGE_CONTACT':
      return {
        ...state,
        contact: action.payload,
        isDirty: true,
      };
    case 'CLEAR_SCHEDULE_TIME': {
      return {
        ...state,
        scheduleTime: action.payload,
      };
    }
    case 'CHANGE_RESPONSIBLE':
      return {
        ...state,
        responsible: action.payload,
        isDirty: true,
      };
    case 'SET_SCHEDULE_TIME':
      return {
        ...state,
        scheduleTime: {
          ...action.payload,
        },
      };
    case 'SET_IS_DIRTY': {
      return { ...state, isDirty: action?.payload };
    }
    case 'EDIT_TITLE':
      return {
        ...state,
        title: action.payload,
      };

    default:
      return state;
  }
};

export const TicketDetails = () => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const { ticketId } = useParams<string>();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);

  // const owner = JSON.parse(window.localStorage.getItem('OWNER'));

  // states
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  // api

  let isEnabled = ticketId !== 'new' ? true : false;
  let _ticketId = 'new';

  if (ticketId !== undefined) {
    _ticketId = ticketId.toString();
  }
  const { data: ticketData } = useGet(
    rq.ticket(_ticketId, isEnabled, '0'),
    isEnabled
  );

  isEnabled =
    state.companyId !== 'new' && state.contactId !== 'new' ? true : false;
  console.log('state.companyId', state.companyId);
  console.log('state.contactId', state.contactId);
  const { data: employeeWithProductsData } = useGet(
    rq.employeeWithProducts(state.companyId, state.contactId, isEnabled),
    isEnabled
  );

  isEnabled = state.companyId !== 'new' ? true : false;
  const { data: allCompaniesData } = useGet(
    rq.companies('client', isEnabled),
    isEnabled
  );

  isEnabled = state.companyId !== 'new' ? true : false;
  const { data: allCompanyEmployees } = useGet(
    rq.appEmployees(state.companyId, isEnabled),
    isEnabled
  );

  console.log('allCompanyEmployees =>', allCompanyEmployees);

  //console.log('employeeWithProductsData', employeeWithProductsData);
  // console.log('ticket data', ticketData);
  // console.log('state.ticket.comment', state.ticket.comment);

  const postTicket = usePost(
    rqPost.ticket(state?.companyId || '', 1, true),
    true
  );

  // api

  const { data: mobileAutoAnswersData } = useGet(
    rq.mobileAutoAnswers('BBB00BBB-6C0C-4C0C-8C0C-BBB001BBB001', true),
    true
  );

  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  console.log('employeeId', state.contactId);
  let companyId = state?.companyId ? state.companyId : 'new';
  let employeeId = state.contactId ? state.contactId : 'new';
  employeeId = 'new';
  companyId = 'new';
  console.log('employeeId new', state.contactId);

  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    console.log('objectListData useEffect', objectDrawerList);
    // setResource(objectDrawerList[Number(EnumObjectType.Resource)].objectList);
    if (allCompaniesData !== null) {
      let _allCompaniesData = _.cloneDeep(allCompaniesData?.companies || []);
      objectDrawerList[EnumObjectType.Company].objectList = _allCompaniesData;
    }

    if (allCompanyEmployees !== null) {
      let _allCompanyEmployees = _.cloneDeep(
        allCompanyEmployees?.allEmployees || []
      );
      objectDrawerList[EnumObjectType.CompanyEmployees].objectList =
        _allCompanyEmployees;
    }
    setIsObjectList(false);
  }, [objectDrawerList]);

  const putTicket = usePut(
    rqPost.ticketPut(_ticketId, '0', isEnabled),
    isEnabled
  );

  // useEffect set initial ticket state
  useEffect(() => {
    if (ticketData) {
      console.log('TICKETDATA =>', ticketData);
      dispatch({ type: 'SET_TICKET', payload: ticketData });

      setIsObjectList(true);
    }
  }, [ticketData]);

  useEffect(() => {
    //setIsObjectList(true);
    if (employeeWithProductsData) {
      dispatch({
        type: 'SET_COMPANY_PRODUCTS',
        payload: employeeWithProductsData?.companyProducts,
      });
      objectDrawerList[EnumObjectType.CompanyProduct].objectList =
        employeeWithProductsData?.companyProducts;
      setIsObjectList(true);
    }
  }, [employeeWithProductsData]);

  /* FUNCTIONS */

  // handles
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    // console.log('handleChangeDeliveryAddress', deliveryAddress);
    if (deliveryAddress && deliveryAddress?.id) {
      dispatch({
        type: 'SET_DELIVERY_ADDRESS',
        payload: deliveryAddress,
      });
    }
  };

  const handleChangeSchedule = (changeType: string, e: any) => {
    let scheduleTime = _.cloneDeep(state.scheduleTime);
    switch (changeType.toUpperCase()) {
      case 'DELIVERY_DATE':
        scheduleTime.deliveryDate = e;
        // Check if the deliveryDate is a Monday
        if (dayjs(scheduleTime.deliveryDate).day() === 1) {
          // Day.js treats Sunday as 0, Monday as 1, and so on.
          // Set startDate to the previous Friday at 07:00
          const startDateDayjs = dayjs(scheduleTime.deliveryDate)
            .subtract(3, 'day')
            .set('hour', 7)
            .set('minute', 0);
          scheduleTime.startDate = startDateDayjs;
        }
        break;
      case 'SCHEDULED_DATE':
        scheduleTime.startDate = e;
        break;
      case 'SCHEDULE_TYPE':
        scheduleTime.scheduleType = e;
        break;
      case 'HOURS':
        scheduleTime.estimatedTime.hours = e;
        break;
      case 'MINUTES':
        scheduleTime.estimatedTime.minutes = e;
        break;
    }

    dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    });
  };

  const handleResponsibleChange = (
    event: any,
    responsible: IEmployeeViewModel
  ) => {
    dispatch({ type: 'SET_RESPONSIBLE', payload: responsible });
  };

  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
      case EnumObjectType.CompanyProduct:
        if (companyProduct?.resourceId) {
          // Utfører første dispatch-kall
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              resourceId: null,
              resource: null,
              placement: EnumPlacement.NONE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: true,
          });
        } else {
          // Utfører dispatch-kall hvis betingelsen ikke er oppfylt
          dispatch({
            type: 'UPDATE_COMPANY_PRODUCT',
            payload: {
              ...companyProduct,
              //resourceId: state.id,
              placement: EnumPlacement.RESOURCE,
            },
          });
          // Utfører andre dispatch-kall
          dispatch({
            type: 'SET_DIRTY',
            payload: false,
          });
        }
        break;

      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        dispatch({
          type: 'SET_DIRTY',
          payload: false,
        });
        break;
    }

    let isStorageProductEmpty =
      state?.storageProducts?.length === 0 &&
      state?.productCatalog?.length === 0
        ? 0
        : 1;
    dispatch({
      type: 'SET_BOARD',
      payload: isStorageProductEmpty,
    });
  };

  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Product:
        let product = ConvertProductToCompanyProduct(
          selectedObject as IBillingProductViewModel
        );
        dispatch({
          type: 'ADD_PRODUCT',
          payload: product as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.CompanyProduct:
        dispatch({
          type: 'ADD_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'ADD_UNASSIGNED_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });

        break;
      case EnumObjectType.StorageProduct:
        // convert to company product
        let storageProduct = ConvertStorageProductToCompanyProduct(
          selectedObject as IStorageProductViewModel
        );
        dispatch({
          type: 'ADD_STORAGE_PRODUCT',
          payload: storageProduct as ICompanyProductViewModel,
        });

        break;
    }
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
    let isStorageProductEmpty =
      state.storageProducts.length === 0 && state.productCatalog.length === 0
        ? 0
        : 1;
    dispatch({
      type: 'SET_BOARD',
      payload: isStorageProductEmpty,
    });
  };

  const handleBlur = (e: any) => {
    e.preventDefault();
    // console.log('blur', e.target.value);
    // console.log('blur', e.target.value);
    let comment = e.target.value;
    dispatch({ type: 'EDIT_COMMENT', payload: comment });
  };

  const handlePriorityChange = (e: any, priority: EnumPriority) => {
    e.preventDefault();
    dispatch({ type: 'SET_PRIORITY', payload: priority });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };

  const handleChangeCompanyProduct = (
    event: any,
    companyProduct: ICompanyProductViewModel
  ) => {
    dispatch({ type: 'SET_COMPANY_PRODUCT', payload: companyProduct });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };
  const handleChangeAutoAnswer = (
    event: any,
    autoAnswer: IAutoAnswersViewModel
  ) => {
    console.log('handleChangeAutoAnswer', autoAnswer);
    dispatch({
      type: 'SET_AUTO_ANSWER',
      payload: autoAnswer,
    });
    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };

  const handleChangeEmployee = (
    e: any,
    employee: IEmployeeWithCompanyViewModel
  ) => {
    e.preventDefault();
    let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.addressList,
      owner,
      employee
    );

    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    });

    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });

    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });

    setIsObjectList(false);
  };

  const handleChangeCompany = (e: any, company: ICompanyViewModel) => {
    e.preventDefault();
    setIsObjectList(true);
    let employee: IEmployeeWithCompanyViewModel = {
      id: 'new',
      name: '',
      department: '',
      phone: '',
      email: '',
      budget: 0,
      title: '',
      url: '',
      workRole: null,
      company: {} as ICompanyViewModel,
      departmentAddress: {} as IAddressViewModel,
      companyAddress: company.mainAddress,
      homeOffice: company?.contact?.address || undefined || null,
    };

    let tmp: IDeliveryAddressViewModel[] = GenerateAddressList(
      state.addressList,
      owner,
      employee
    );

    dispatch({
      type: 'UPDATE_ADDRESS_LIST',
      payload: tmp,
    });
    dispatch({
      type: 'SET_COMPANY',
      payload: company,
    });

    dispatch({
      type: 'SET_CONTACT',
      payload: employee,
    });

    dispatch({
      type: 'SET_IS_DIRTY',
      payload: true,
    });
  };

  const handleContactSubmit = (e: any) => {
    // console.log('handleContactSubmit', e);
    dispatch({
      type: 'CHANGE_CONTACT',
      payload: e,
    });
  };

  useEffect(() => {
    if (mobileAutoAnswersData) {
      // console.log('mobileAutoAnswersData', mobileAutoAnswersData);
      let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
      dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
      dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
    }
  }, [mobileAutoAnswersData]);

  const onSubmit = () => {
    let updatedTicket = ConvertStateToTicketUpdateWithProductViewModel(
      state,
      user
    );

    console.log('UPDATETICKET =>', updatedTicket);
    putTicket.mutate(updatedTicket, {
      onSuccess: (state: any) => {
        dispatch({ type: 'RESET_STATE' });
        dispatch({
          type: 'CLEAR_SCHEDULE_TIME',
          payload: {
            id: '0', // or null, if that's more appropriate for a cleared state
            scheduleType: EnumScheduleType.None,
            deliveryDate: dayjs(new Date()), // or null/undefined, based on what you consider "cleared"
            startDate: dayjs(new Date()), // or null/undefined
            estimatedTime: {
              hours: 0,
              minutes: 0,
            },
          },
        });
        let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
        dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
        dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
      },
    });
  };

  const toggleDisabled = () => {
    setIsDisabled(!isDisabled);
  };

  const handleChangeTitle = (e: any) => {
    dispatch({ type: 'EDIT_TITLE', payload: e.target.value });
    dispatch({ type: 'SET_IS_DIRTY', payload: true });
  };
  const handleChangeProductData = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    console.log('handleChangeProductData', objectType, companyProduct);
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'UPDATE_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({ type: 'UPDATE_COMPANY_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        break;
      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'UPDATE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        break;
    }
    console.log('handleChangeProductData - x', state.productCatalog);
  };

  const onSubmitProduct = () => {
    let updatedTicket = ConvertStateToTicketUpdateWithProductViewModel(
      state,
      user
    );

    console.log('UPDATETICKET =>', updatedTicket);
    putTicket.mutate(updatedTicket, {
      onSuccess: (state: any) => {
        dispatch({ type: 'RESET_STATE' });
        dispatch({
          type: 'CLEAR_SCHEDULE_TIME',
          payload: {
            id: '0', // or null, if that's more appropriate for a cleared state
            scheduleType: EnumScheduleType.None,
            deliveryDate: dayjs(new Date()), // or null/undefined, based on what you consider "cleared"
            startDate: dayjs(new Date()), // or null/undefined
            estimatedTime: {
              hours: 0,
              minutes: 0,
            },
          },
        });
        let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
        dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
        dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5] });
      },
    });
  };

  console.log('state', state);

  return (
    <>
      <Grid
        container
        sx={{ flexGrow: 1, m: 0, display: 'flex', flexDirection: 'row' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            mb: 1,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              p: 1,
              borderRadius: 2,
              backgroundColor: 'primary.main',
            }}
          >
            {/* HEADER  */}
            <Grid container display={'flex'} direction='row' flexGrow={1}>
              <Grid item xs={4} display={'flex'} flexDirection={'row'}>
                <ConfirmationNumberRoundedIcon sx={{ fontSize: 32, m: 0 }} />
                <Typography
                  sx={{
                    m: 0,
                    ml: 1,
                    pt: 1,
                    flexGrow: 1,
                    textTransform: 'uppercase',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: 2,
                  }}
                >
                  {state.ticketNo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Grid item xs={3} sx={{ pr: 2 }}>
                  <Select
                    sx={{ width: '100%', p: 0, mt: -0.5 }}
                    labelId='Board'
                    id='Board'
                    variant='standard'
                    value={state.board}
                    label='Board'
                    onChange={(e) => {
                      dispatch({ type: 'SET_BOARD', payload: e.target.value });
                    }}
                  >
                    <MenuItem value={0}>
                      {t('Ticket.TicketDetails.ticketTypes.sale')}
                    </MenuItem>
                    <MenuItem value={1}>
                      {t('Ticket.TicketDetails.ticketTypes.support')}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t('Ticket.TicketDetails.ticketTypes.project')}
                    </MenuItem>
                    <MenuItem value={3}>
                      {t('Ticket.TicketDetails.ticketTypes.unknown')}
                    </MenuItem>
                    <MenuItem value={4}>
                      {t('Ticket.TicketDetails.ticketTypes.noReply')}
                    </MenuItem>
                    <MenuItem value={5}>
                      {t('Ticket.TicketDetails.ticketTypes.spam')}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* LEFT SIDE  */}
        <Grid
          item
          xs={6}
          sx={{
            m: 0,
            pr: 0.5,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              height: Math.ceil(height - 252),
              display: 'flex',
              flexDirection: 'column',
              p: 1,
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* ASSIGN CUSTOMER EMPLOYEE HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title={t('Ticket.TicketDetails.assignCustomerEmployee')}
                />
              </Grid>
              {/* ASSIGN CUSTOMER EMPLOYEE */}
              <Grid item xs={6} sx={{ p: 0, mb: 0.5, position: 'relative' }}>
                {state?.contact?.id != '' ? (
                  <Stack direction='column'>
                    <SingleTicketEmployeeView
                      employee={state.contact}
                      priority={state.priority}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        top: -40,
                        right: 0,
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.CompanyEmployees]}
                        handleChange={handleChangeEmployee}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <ObjectDrawer
                    buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.CompanyEmployees]}
                    handleChange={handleChangeEmployee}
                    buttonHeight={64}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              <Grid item xs={6} sx={{ p: 0, mb: 0.5, position: 'relative' }}>
                {state.company ? (
                  <Stack direction='column'>
                    <SingleTicketCompanyView company={state.company} />
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        top: -40,
                        right: 0,
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.Company]}
                        handleChange={handleChangeCompany}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </Stack>
                ) : (
                  <ObjectDrawer
                    buttonContext={t('Ticket.TicketDetails.selectEmployee')}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.Company]}
                    handleChange={handleChangeCompany}
                    buttonHeight={82}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              {/* PRIORITY HEADER */}
              <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
                <FormBox
                  Icon={PriorityHighRoundedIcon}
                  title={t('Ticket.TicketDetails.priority')}
                />
              </Grid>
              {/* PRIORITY BUTTONS  */}
              <Grid
                item
                xs={12}
                sx={{
                  p: 0,
                  mb: 1,
                  flexGrow: 1,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                  size='small'
                  sx={{ width: '100%' }}
                >
                  <Button
                    key='NONE'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.priority === EnumPriority.NONE
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NONE),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 0);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.none')}
                  </Button>
                  <Button
                    key='LOW'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.priority === EnumPriority.LOW
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.LOW),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 1);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.low')}
                  </Button>
                  <Button
                    key='NORMAL'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.priority === EnumPriority.NORMAL
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.NORMAL),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 2);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.normal')}
                  </Button>
                  <Button
                    key='HIGH'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.priority === EnumPriority.HIGH
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(EnumPriority.HIGH),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 3);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.high')}
                  </Button>

                  <Button
                    key='CRITICAL'
                    sx={{
                      width: '20%',
                      fontSize: 14,
                      backgroundColor:
                        state.priority === EnumPriority.CRITICAL
                          ? GetPriorityColor(state.priority)
                          : 'primary.back',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(
                          EnumPriority.CRITICAL
                        ),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 4);
                    }}
                  >
                    {t('Ticket.TicketDetails.priorityLevels.critical')}
                  </Button>
                </ButtonGroup>
              </Grid>
              {/* ISSUE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title={t('Ticket.TicketDetails.ticketDescription')}
                />
              </Grid>
              {/* ISSUE TYPE */}
              <Grid item xs={5} sx={{ m: 0, pr: 1, mb: -1 }}>
                {!state?.supportType ? (
                  <>
                    <ObjectDrawer
                      buttonContext={t('Ticket.TicketDetails.selectIssueType')}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.IssueType]}
                      handleChange={handleChangeAutoAnswer}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  </>
                ) : (
                  <>
                    {state.supportType && (
                      <SingleAutoAnswerView autoAnswer={state.supportType} />
                    )}
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 4,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t(
                          'Ticket.TicketDetails.selectIssueType'
                        )}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.IssueType]}
                        handleChange={handleChangeAutoAnswer}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {/* ISSUE PRODUCT */}
              <Grid item xs={7} sx={{ m: 0, pl: 1, mb: -1 }}>
                {state.companyProductWithError ? (
                  <>
                    <SingleTicketContactProduct
                      product={state.companyProductWithError}
                    />
                    <Grid
                      item
                      sx={{
                        position: 'relative',
                        bottom: 78,
                        right: 8,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ObjectDrawer
                        buttonContext={t(
                          'Ticket.TicketDetails.selectCompanyProduct'
                        )}
                        buttonIcon={
                          <ChangeCircleIcon sx={{ fontSize: 26, m: 1.5 }} />
                        }
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.EmployeeProducts]}
                        handleChange={handleChangeCompanyProduct}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Grid>
                  </>
                ) : (
                  state.companyProducts &&
                  state.companyProducts.length > 0 && (
                    <ObjectDrawer
                      buttonContext={t(
                        'Ticket.TicketDetails.selectCompanyProduct'
                      )}
                      buttonIcon={null}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.EmployeeProducts]}
                      handleChange={handleChangeCompanyProduct}
                      buttonHeight={80}
                      buttonWidth={'100%'}
                    />
                  )
                )}
              </Grid>

              {/* JOBTYPE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox Icon={DevicesOtherRoundedIcon} title={'Job Type'} />
              </Grid>
              {/* JOB TYPE */}
              <Grid item xs={3} sx={{ pr: 2 }}>
                <Select
                  sx={{ width: '100%', p: 0, mt: -0.5 }}
                  labelId='JobType'
                  id='JobType'
                  variant='standard'
                  value={state.jobType}
                  label='Job Type'
                  onChange={(e) => {
                    dispatch({ type: 'SET_JOB_TYPE', payload: e.target.value });
                  }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={1}>Support</MenuItem>
                  <MenuItem value={2}>OnBoarding</MenuItem>
                  <MenuItem value={3}>OffBoarding</MenuItem>
                  <MenuItem value={4}>ReplaceProduct</MenuItem>
                  <MenuItem value={5}>AssignProduct</MenuItem>
                  <MenuItem value={6}>NewProduct</MenuItem>
                  <MenuItem value={7}>Service</MenuItem>
                </Select>
              </Grid>

              {/* TICKET TITLE */}
              <Grid
                item
                xs={12}
                sx={{ mb: 1 }}
                display={'flex'}
                flexDirection={'row'}
              >
                <TextField
                  autoFocus
                  id='name'
                  label={t('Ticket.TicketDetails.title')}
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={isDisabled}
                  value={state.title}
                  onChange={(e) => handleChangeTitle(e)}
                  sx={{ mr: 2 }}
                />
                <Button
                  variant='contained'
                  sx={{
                    height: 32,
                    width: 32,
                    minWidth: 32,
                    padding: 0,
                    backgroundColor: 'primary.light',
                    borderRadius: 2,
                  }}
                  onClick={toggleDisabled}
                >
                  <EditIcon sx={{ height: 20, width: 20 }} />
                </Button>
              </Grid>

              {/* ISSUE HEADER */}
              <Grid item xs={12} sx={{ p: 0, pr: 1, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title={t('Ticket.TicketDetails.editIssueDescription')}
                />
              </Grid>
              {/* ISSUE DESCRIPTION */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <TextField
                  sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                  value={state.comment}
                  defaultValue={state.comment}
                  label={t('Ticket.TicketDetails.issueDescription')}
                  variant='filled'
                  multiline={true}
                  disabled={true}
                  minRows={4}
                  maxRows={4}
                  onChange={(e) => {
                    dispatch({
                      type: 'EDIT_COMMENT',
                      payload: e.target.value,
                    });
                    dispatch({
                      type: 'SET_IS_DIRTY',
                      payload: true,
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              {/* PRODUCT HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={DevicesOtherRoundedIcon}
                  title={t('Ticket.TicketDetails.salesProducts')}
                  FormBTN={
                    <ObjectDrawer
                      buttonContext={t('Ticket.TicketDetails.addProduct')}
                      buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[
                        EnumObjectType.Product,
                        EnumObjectType.StorageProduct,
                        EnumObjectType.unassignedCompanyProduct,
                        // EnumObjectType.Package,
                      ]}
                      handleChange={handleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  }
                />
              </Grid>
              {/* PRODUCTS */}
              <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                <List
                  sx={{
                    width: '100%',
                    height: Math.ceil(height - 965),
                    //p: 2,
                    overflow: 'auto',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {state?.unassignedCompanyProducts?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                          <ProductCardView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.unassignedCompanyProduct}
                            handleRemoveProduct={handleRemoveProduct}
                            handleChange={handleChangeProductData}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                  {state?.storageProducts?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                          <ProductCardView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.StorageProduct}
                            handleRemoveProduct={handleRemoveProduct}
                            handleChange={handleChangeProductData}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                  {state?.productCatalog?.map(
                    (
                      companyProduct: ICompanyProductViewModel,
                      index: number
                    ) => {
                      return !!companyProduct ? (
                        <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                          <ProductCardView
                            companyProduct={companyProduct}
                            location={EnumObjectType.Resource}
                            objectType={EnumObjectType.Product}
                            handleRemoveProduct={handleRemoveProduct}
                            handleChange={handleChangeProductData}
                          />
                        </ListItem>
                      ) : null;
                    }
                  )}
                </List>
                <Grid
                  item
                  xs={12}
                  sx={{
                    p: 1,
                    m: 0,
                    borderRadius: 2,
                    backgroundColor: 'primary.main',
                    display: 'flex',
                    flexDirection: 'flex-end',
                  }}
                >
                  <Button
                    sx={{ width: 250, marginLeft: 'auto' }}
                    variant='contained'
                    //type='submit'
                    onClick={onSubmitProduct}
                    disabled={state.productCatalog.length === 0}
                  >
                    {t('Ticket.TicketDetails.addProdukt')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* RIGHT SIDE  */}
        <Grid
          item
          xs={6}
          sx={{
            m: 0,
            pl: 0.5,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{
              height: Math.ceil(height - 252),
              display: 'flex',
              flexDirection: 'column',
              p: 1,
              mb: 1,
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {/* ASSIGN RESPONSIBLE HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title={t('Ticket.TicketDetails.assignResponsible')}
                  FormBTN={
                    <ObjectDrawer
                      buttonContext={t(
                        'Ticket.TicketDetails.assignResponsible'
                      )}
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                      objectList={!!objectDrawerList ? objectDrawerList : []}
                      objectType={[EnumObjectType.AppOwnerEmployees]}
                      handleChange={handleResponsibleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  }
                />
              </Grid>
              {/* ASSIGN RESPONSIBLE */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                {!!state.responsible ? (
                  <Stack direction='column'>
                    <SingleEmployeeView employee={state.responsible} />
                  </Stack>
                ) : (
                  <ObjectDrawer
                    buttonContext={t('Ticket.TicketDetails.selectResponsible')}
                    buttonIcon={null}
                    objectList={!!objectDrawerList ? objectDrawerList : []}
                    objectType={[EnumObjectType.AppOwnerEmployees]}
                    handleChange={handleResponsibleChange}
                    buttonHeight={80}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              {/* ASSIGN TECHNICIAN HEADER */}
              <Grid item xs={12} sx={{ m: 0, p: 0, mb: 1 }}>
                <FormBox
                  Icon={PersonSearchRoundedIcon}
                  title={t('Ticket.TicketDetails.assignTechnicians')}
                  /*  FormBTN={
              <ObjectDrawer
                buttonContext={'Assign Responsible'}
                buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                objectList={!!objectDrawerList ? objectDrawerList : []}
                objectType={[
                  EnumObjectType.AppOwnerEmployees,
                ]}
                handleChange={handleChange}
                buttonHeight={26}
                buttonWidth={26}
              />
            } */
                />
              </Grid>
              {/* TECHNICIANS */}
              <Grid item xs={12} sx={{ p: 0, m: 0, mb: 1 }}>
                <Button
                  variant='contained'
                  sx={{
                    //border: 1,
                    borderRadius: 2,
                    //borderColor: 'primary.dark',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    width: '100%',
                    height: 170,
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 2,
                    }}
                  >
                    {t('Ticket.TicketDetails.underConstruction')}
                  </Typography>
                </Button>
                {/*    {state?.technicians.length > 0 ? (
            <List
              sx={{
                height: Math.ceil(height - 850),
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 2,
              }}
            >
              <Box sx={{ mb: 1 }}>
                <ObjectDrawer
                  buttonContext={'Add Technician'}
                  buttonIcon={null}
                  objectList={!!ccs ? ccs.allEmployees : []}
                  objectType={[EnumObjectType.CompanyEmployee]}
                  handleChange={handleTechniciansChange}
                  buttonHeight={80}
                  buttonWidth={'100%'}
                />
              </Box>
              {state?.technicians.length > 0 &&
                state.technicians?.map(
                  (technician: any, index: number) => (
                    <>
                      <SingleEmployeeView
                        key={index}
                        employee={technician}
                        action={PersonRemoveRoundedIcon}
                        gfx={
                          <TechnicianGfx
                            handleRemoveTechnician={
                              handleRemoveTechnician
                            }
                            technician={technician}
                          />
                        }
                      />
                    </>
                  )
                )}
            </List>
          ) : (
            <ObjectDrawer
              buttonContext={'Select Technicians'}
              buttonIcon={null}
              objectList={!!ccs ? ccs.allEmployees : []}
              objectType={[EnumObjectType.CompanyEmployee]}
              handleChange={handleTechniciansChange}
              buttonHeight={182}
              buttonWidth={'100%'}
            />
          )} */}
              </Grid>
              {/* SCHEDULE */}
              <Grid item xs={12} sx={{ p: 0, m: 0, mb: 1 }}>
                {state.scheduleTime && (
                  <ScheduleView
                    scheduleTime={state.scheduleTime}
                    handleChange={handleChangeSchedule}
                  />
                )}
              </Grid>
              {/* DELIVERY ADDRESS */}
              <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                <DeliveryAddressView
                  addressList={state.addressList || []}
                  handleChange={handleChangeDeliveryAddress}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* FOOTER SIDE  */}
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            m: 0,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            display: 'flex',
            flexDirection: 'flex-end',
          }}
        >
          <Button
            sx={{ width: 150, marginLeft: 'auto' }}
            variant='contained'
            //type='submit'
            onClick={onSubmit}
            disabled={
              postTicket?.isLoading ||
              !state.contact.id ||
              !state.companyId ||
              !state.supportType ||
              !state.comment ||
              !state.isDirty
            }
          >
            {t('Ticket.TicketDetails.update')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
