import { combineReducers } from 'redux';
import { initialTicketState, TicketReducer } from './TicketReduces';
import { TicketPostReducer, TicketPostState } from './TicketPostReducer';
import {
  ChecklistState,
  initialChecklistState,
  TicketChecklistReducer,
} from './TicketCheckListReducer';
import {
  ITicketDetailsInitialState,
  ITicketPostCommunicationData,
} from 'Interfaces/ITicketConfig';

// Kombiner staten fra de ulike reducerne
export interface RootState {
  ticket: ITicketDetailsInitialState;
  ticketPost: ITicketPostCommunicationData;
  checklist: ChecklistState;
}

// Initial root state kombinerer alle initial states
export const initialRootState: RootState = {
  ticket: initialTicketState,
  ticketPost: TicketPostState,
  checklist: initialChecklistState,
};

export const RootReducer = combineReducers<RootState>({
  ticket: TicketReducer,
  ticketPost: TicketPostReducer,
  checklist: TicketChecklistReducer,
});
