import dayjs from 'dayjs';
import { ThirdPartEnum } from 'Interfaces/ICompaniesConfig';
import {
  EnumBindingPeriod,
  EnumCycleAlignment,
  EnumProductSecurity,
  EnumProductVariant,
  EnumTempProductType,
  IFileAddViewModel,
} from 'Interfaces/IGlobalTypeConfig';

export interface IImportEmployeeAddViewModel {
  name: string;
  creatorId: string;
  companyId: string;
  phone: string;
  email: string;
  departmentId: string;
  primaryContact: boolean;
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;
  productIds: string[];
}
export interface IImportEmployeeAddListViewModel {
  employees: IImportEmployeeAddViewModel[];
}
export interface IImportResourceAddViewModel {
  name: string;
  email: string;
  resourceTypeId: string;
  companyId: string;
  departmentId: string;
  creatorId: string;
  contactId: null | string;
  legacyId: number;
  thirdPartyId: string;
  thirdParty: ThirdPartEnum;
}
export interface IImportResourceAddListViewModel {
  resources: IImportResourceAddViewModel[];
}
export interface IImportProductAddViewModel {
  name: string;
  companyId: string;
  comment: string | null;
  serialNumber: string | null;
  unitPrice: number;
  retailPrice: number;
  security: EnumProductSecurity;
  fileModel: IFileAddViewModel | null;
  tempType: EnumTempProductType | null;
  imageFile: string;
  productTypeId: string;
  EAN: string;
  brandId: string;
  quantity: number;
  paymentCycle: EnumBindingPeriod;
  cycleAlignment: EnumCycleAlignment;
  bindingPeriod: EnumBindingPeriod;
  productFeatureDataIds: string[];
  legacyId: number;
  thirdPartyId: string;
  thirdParty: number;
  purchaserId: string;
  productVariant: EnumProductVariant; // EnumProductVariant;
}

export interface IImportProductListViewModel {
  products: IImportProductAddViewModel[];
}

export const removeLastColonPart = (input: string): string => {
  const lastIndex = input.lastIndexOf(':');
  if (lastIndex === -1) {
    return input; // Returner input uendret hvis ingen kolon finnes
  }
  return input.substring(0, lastIndex);
};
