import dayjs from 'dayjs';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import {
  addressListData,
  EnumAddressType,
  EnumBillingType,
  EnumBoard,
  EnumJobType,
  EnumPriority,
  EnumScheduleType,
  EnumSupportBilling,
  EnumTicketStatus,
  IDeliveryAddressViewModel,
  IMobileAnswersViewModel,
  IScheduleViewModel,
} from 'Interfaces/IGlobalTypeConfig';
import {
  IEmployeeViewModel,
  IEmployeeWithCompanyViewModel,
  ITicketCompanyViewModel,
  ITicketDetailsInitialState,
  ITicketViewModel,
} from 'Interfaces/ITicketConfig';

export const initialTicketState: ITicketDetailsInitialState = {
  id: 'new',
  ticketNo: 'new',
  board: EnumBoard.Support,
  priority: EnumPriority.NORMAL,
  status: EnumTicketStatus.New,
  companyId: 'new',
  company: {} as unknown as ITicketCompanyViewModel,
  contactId: 'new',
  contact: {} as unknown as IEmployeeViewModel,
  creatorId: 'new',
  creator: {} as unknown as IEmployeeViewModel,
  responsibleId: null,
  responsible: {} as unknown as IEmployeeViewModel,
  shippingCost: 0,
  deliveryAddress: {} as IDeliveryAddressViewModel,
  scheduleTime: {
    id: '0',
    scheduleType: EnumScheduleType.None,
    deliveryDate: dayjs(new Date()),
    startDate: dayjs(new Date()),
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  },
  jobType: EnumJobType.Support,
  supportType: {} as IMobileAnswersViewModel,
  errorDescriptionId: '',
  errorDescription: {} as IMobileAnswersViewModel,
  companyProductWithErrorId: '',
  companyProductWithError: {} as ICompanyProductViewModel,
  technicians: null,
  comment: '',
  ccEmail: '',
  fixedHourlyRate: 0,
  fixedTime: 0,
  fixedPrice: 0,
  maxTime: 0,
  maxPrice: 0,
  productBilling: true,
  updated: dayjs(new Date()),
  billing: EnumBillingType.SystemPeriod,
  title: '',
  companyProducts: [] as ICompanyProductViewModel[],
  techniciansId: [] as string[],
  addressList: addressListData,
  storageProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[],
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  isDirty: false,
  supportBilling: EnumSupportBilling.BillOnCompletion,
};

type TicketAction =
  | { type: 'RESET_TICKET_STATE' }
  | { type: 'EDIT_CC'; payload: string }
  | { type: 'EDIT_COMMENT'; payload: any }
  | { type: 'SET_TICKET'; payload: ITicketViewModel }
  | { type: 'SET_BOARD'; payload: any }
  | { type: 'SET_JOB_TYPE'; payload: any }
  | { type: 'UPDATE_ADDRESS_LIST'; payload: IDeliveryAddressViewModel[] }
  | { type: 'SET_CONTACT'; payload: IEmployeeWithCompanyViewModel }
  | { type: 'SET_COMPANY'; payload: any }
  | { type: 'SET_PRIORITY'; payload: EnumPriority }
  | { type: 'SET_AUTO_ANSWER'; payload: any }
  | { type: 'SET_AUTO_ANSWERS'; payload: any }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | {
      type: 'SET_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'SET_COMPANY_PRODUCTS';
      payload: ICompanyProductViewModel[];
    }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'ADD_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'REMOVE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'SET_DIRTY';
      payload: boolean;
    }
  | { type: 'SET_DELIVERY_ADDRESS'; payload: IDeliveryAddressViewModel }
  | {
      type: 'UPDATE_UNASSIGNED_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'SET_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'SET_ISSUE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'CHANGE_PRIORITY'; priority: number }
  | { type: 'CHANGE_BOARD'; payload: number }
  | { type: 'CHANGE_CONTACT'; payload: IEmployeeViewModel }
  | { type: 'CHANGE_RESPONSIBLE'; payload: IEmployeeViewModel }
  | { type: 'CHANGE_TECHNICIAN'; payload: IEmployeeViewModel[] }
  | { type: 'SET_IS_DIRTY'; payload: boolean }
  | { type: 'CLEAR_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'EDIT_TITLE'; payload: string }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel };

export const TicketReducer = (
  state: ITicketDetailsInitialState = initialTicketState,
  action: TicketAction
): ITicketDetailsInitialState => {
  switch (action.type) {
    case 'EDIT_CC':
      return {
        ...state,
        ccEmail: action.payload,
      };
    case 'ADD_PRODUCT':
      //console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      // console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'RESET_TICKET_STATE':
      return initialTicketState;
    case 'ADD_STORAGE_PRODUCT':
      // console.log('ADD_STORAGE_PRODUCT', action?.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action?.payload],
      };

    case 'UPDATE_STORAGE_PRODUCT':
      return {
        ...state,
        storageProducts: state.storageProducts.map((product) =>
          product.id === action.payload.id
            ? { ...product, ...action.payload }
            : product
        ),
      };
    case 'REMOVE_STORAGE_PRODUCT':
      // console.log('REMOVE_STORAGE_PRODUCT', action?.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product: any) => product.id !== action?.payload.id
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action?.payload],
      };
    case 'UPDATE_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product: any) =>
          product.id === action?.payload.id ? action?.payload : product
        ),
      };
    case 'ADD_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action?.payload,
        ],
      };
    case 'REMOVE_UNASSIGNED_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action?.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product: any) => product.id !== action?.payload.id
        ),
      };
    case 'SET_TICKET':
      console.log('SET_TICKET payload', action?.payload);
      let deliveryDate =
        action.payload?.deliveryDate !== null
          ? action.payload.deliveryDate
          : dayjs(new Date());
      let startDate =
        action.payload?.deliveryDate !== null
          ? action.payload.deliveryDate
          : dayjs(new Date());

      let schedule = {
        id: '',
        scheduleType: action.payload?.deliveryDate
          ? EnumScheduleType.Reminder
          : EnumScheduleType.None,
        deliveryDate: deliveryDate,
        startDate: startDate,
        estimatedTime: {
          hours: action.payload?.ETCHours || 0,
          minutes: action.payload?.ETCMinutes || 0,
        },
      };

      let address = {
        id: null,
        addressId: null,
        addressType: EnumAddressType.custom,
        street: action.payload.deliveryStreet,
        postalCode: action.payload.deliveryPostalCode,
        area: action.payload.deliveryArea,
        city: action.payload.deliveryCity,
        country: action.payload.deliveryCountry,
        postBox: null,
        attention: action.payload.attention,
      };

      let technicians = null;
      return {
        ...state,
        id: action.payload.id,
        ticketNo: action.payload.ticketNo,
        board: action.payload.board,
        priority: action.payload.priority,
        status: action.payload.status,
        companyId: action.payload.company.id,
        company: action.payload.company,
        contactId: action.payload.contact.id,
        contact: action.payload.contact,
        creatorId: action.payload.creator.id,
        creator: action.payload.creator,
        responsibleId: action.payload.responsible?.id,
        responsible: action.payload.responsible,
        shippingCost: action.payload.shippingCost,
        deliveryAddress: address,
        scheduleTime: schedule,
        jobType: action.payload.jobType,
        supportType: action.payload.supportType,
        errorDescriptionId: action.payload?.errorDescription?.id ?? null,
        errorDescription: action.payload.errorDescription,
        companyProductWithErrorId:
          action.payload?.companyProductWithError?.id ?? null,
        companyProductWithError: action.payload.companyProductWithError,
        technicians: action.payload.technicians,
        comment: action.payload.comment,
        ccEmail: action.payload.ccEmail,
        fixedHourlyRate: action.payload.fixedHourlyRate,
        fixedTime: action.payload.fixedTime,
        fixedPrice: action.payload.fixedPrice,
        maxTime: action.payload.maxTime,
        maxPrice: action.payload.maxPrice,
        productBilling: action.payload.productBilling,
        updated: action.payload.updated,
        billing: action.payload.billing,
        title: action.payload.title,
        techniciansId: technicians,
        isDirty: false,
      };
    case 'SET_BOARD': {
      return {
        ...state,
        board: action?.payload,
      };
    }
    case 'SET_JOB_TYPE': {
      return {
        ...state,
        jobType: action?.payload,
      };
    }

    case 'SET_CONTACT': {
      console.log('SET_CONTACT', action?.payload);
      return {
        ...state,
        contact: action?.payload,
        contactId: action?.payload.id,
      };
    }
    case 'SET_COMPANY':
      return {
        ...state,
        company: action?.payload,
        companyId: action?.payload.id,
      };
    case 'SET_PRIORITY': {
      return {
        ...state,
        priority: action?.payload,
      };
    }
    case 'SET_AUTO_ANSWER': {
      return {
        ...state,
        supportType: action.payload,
      };
    }
    case 'SET_COMPANY_PRODUCTS': {
      return {
        ...state,
        companyProducts: action?.payload,
      };
    }
    case 'SET_COMPANY_PRODUCT': {
      return {
        ...state,
        companyProductWithError: action?.payload,
        companyProductWithErrorId: action?.payload.id,
      };
    }
    case 'SET_RESPONSIBLE': {
      return {
        ...state,
        responsible: action?.payload,
        responsibleId: action?.payload.id,
      };
    }
    case 'SET_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: {
          ...action?.payload,
        },
      };

    case 'EDIT_COMMENT': {
      return {
        ...state,
        comment: action?.payload,
      };
    }

    case 'UPDATE_ADDRESS_LIST':
      return {
        ...state,
        addressList: {
          ...action?.payload,
        },
      };
    case 'CHANGE_PRIORITY':
      return {
        ...state,
        priority: action.priority,
        isDirty: true,
      };
    case 'CHANGE_BOARD':
      return {
        ...state,
        board: action.payload,
        isDirty: true,
      };
    case 'CHANGE_CONTACT':
      return {
        ...state,
        contact: action.payload,
        isDirty: true,
      };
    case 'CLEAR_SCHEDULE_TIME': {
      return {
        ...state,
        scheduleTime: action.payload,
      };
    }
    case 'CHANGE_RESPONSIBLE':
      return {
        ...state,
        responsible: action.payload,
        isDirty: true,
      };
    case 'SET_SCHEDULE_TIME':
      return {
        ...state,
        scheduleTime: {
          ...action.payload,
        },
      };
    case 'SET_IS_DIRTY': {
      return { ...state, isDirty: action?.payload };
    }
    case 'EDIT_TITLE':
      return {
        ...state,
        title: action.payload,
      };

    default:
      return state;
  }
};
