import React, { useEffect, useReducer } from 'react';
import { usePost, useGet } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import {
  Grid,
  Box,
  List,
  ListItem,
  Button,
  Checkbox,
  ListItemText,
  Select,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import { useTranslation } from 'react-i18next';
import { SearchFilter } from 'components/ui/SearchFilter';

import _ from 'lodash';

import {
  ICompanyViewModel,
  IDepartmentViewModel,
} from 'Interfaces/ICompaniesConfig';
import {
  IImportProductAddViewModel,
  IImportProductListViewModel,
} from './Converts/ICloudFactoryInterfaces';
import {
  IProductAddViewModel,
  IProductViewModel,
} from 'Interfaces/IProductConfig';
import { IProductTypeViewModel } from 'Interfaces/BillingConfig';
import { IBrandViewModel } from 'pages/Setup/setupConfig';

export interface IImportProductsInterface {
  products: IImportProductAddViewModel[];
  selectedProducts: string[];
  productType: IProductTypeViewModel[];
  brands: IBrandViewModel[];
  productTypeMapping: { [key: string]: string }; // Mapping of unique key to ProductsTypeId
  brandTypeMapping: { [key: string]: string }; // Mapping of unique key to brandId
}

const initialState: IImportProductsInterface = {
  products: [],
  selectedProducts: [],
  productType: [],
  brands: [],
  productTypeMapping: {}, // Initialize as empty object
  brandTypeMapping: {}, // Initialize as empty object
};

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_PRODUCTS'; payload: IImportProductAddViewModel[] }
  | {
      type: 'UPDATE_PRODUCT_TYPE'; // Update the ProductsTypeId of a product
      payload: { key: string; productTypeId: string };
    }
  | {
      type: 'UPDATE_ALL_PRODUCT_TYPES'; // Update all ProductsTypeId
      payload: string;
    }
  | {
      type: 'UPDATE_PRODUCT_BRAND'; // Update the brandId of a product
      payload: { key: string; brandId: string };
    }
  | {
      type: 'UPDATE_ALL_BRANDS'; // Update all brandId
      payload: string;
    }
  | { type: 'TOGGLE_PRODUCT_SELECTION'; payload: string }
  | { type: 'TOGGLE_ALL_PRODUCT_SELECTION' }
  | { type: 'SET_PRODUCTS_TYPE_LIST'; payload: IProductTypeViewModel[] }
  | { type: 'SET_BRANDS'; payload: IBrandViewModel[] };

const reducer = (
  state: IImportProductsInterface,
  action: Action
): IImportProductsInterface => {
  switch (action.type) {
    case 'RESET_FORM':
      return { ...initialState };
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload };
    case 'UPDATE_PRODUCT_TYPE':
      return {
        ...state,
        productTypeMapping: {
          ...state.productTypeMapping,
          [action.payload.key]: action.payload.productTypeId,
        },
      };
    case 'UPDATE_ALL_PRODUCT_TYPES':
      return {
        ...state,
        productTypeMapping: state.products.reduce<{ [key: string]: string }>(
          (acc, product) => {
            const key = `${product.name}-${product.bindingPeriod}-${product.paymentCycle}`;
            acc[key] = action.payload;
            return acc;
          },
          {}
        ),
      };

    case 'UPDATE_PRODUCT_BRAND':
      return {
        ...state,
        brandTypeMapping: {
          ...state.brandTypeMapping,
          [action.payload.key]: action.payload.brandId,
        },
      };
    case 'UPDATE_ALL_BRANDS':
      return {
        ...state,
        brandTypeMapping: state.products.reduce<{ [key: string]: string }>(
          (acc, product) => {
            const key = `${product.name}-${product.bindingPeriod}-${product.paymentCycle}`;
            acc[key] = action.payload;
            return acc;
          },
          {}
        ),
      };
    case 'TOGGLE_PRODUCT_SELECTION':
      return {
        ...state,
        selectedProducts: state.selectedProducts.includes(action.payload)
          ? state.selectedProducts.filter((key) => key !== action.payload)
          : [...state.selectedProducts, action.payload],
      };
    case 'TOGGLE_ALL_PRODUCT_SELECTION':
      const allSelected =
        state.selectedProducts.length === state.products.length;
      return {
        ...state,
        selectedProducts: allSelected
          ? []
          : state.products.map(
              (product) =>
                `${product.name}-${product.bindingPeriod}-${product.paymentCycle}`
            ),
      };
    case 'SET_PRODUCTS_TYPE_LIST':
      return { ...state, productType: action.payload };
    case 'SET_BRANDS':
      return { ...state, brands: action.payload };
    default:
      return state;
  }
};

type Props = {
  expanded: boolean;
  products: IImportProductAddViewModel[];
  company: ICompanyViewModel | null;
  departments: IDepartmentViewModel[];
  handleRemoveProducts: (products: IProductViewModel[]) => void;
};

export const ImportProductList = ({
  expanded,
  products,
  departments,
  company,
  handleRemoveProducts,
}: Props) => {
  const { t } = useTranslation();
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [user] = useAtom(userAtom);
  const listHeight = Math.ceil(height - 272 - (expanded ? 386 : 0));

  // API CALLS
  const postProducts = usePost(
    rqPost.importProducts(company?.id || '', true),
    true
  );
  let isEnabled = company?.id ? true : false;
  ///PRODUCT TYPES
  const { data: ProductsTypesData } = useGet(rq.productTypes(true), true);
  useEffect(() => {
    if (ProductsTypesData) {
      let tmp = _.cloneDeep(ProductsTypesData || []);
      console.log('NEW : ProductsTypesData', tmp.productType);
      dispatch({ type: 'SET_PRODUCTS_TYPE_LIST', payload: tmp.productType });
    }
  }, [ProductsTypesData]);
  // BRANDS

  const { data: BrandData } = useGet(rq.getAllBrands(1, true), true);
  useEffect(() => {
    if (BrandData) {
      let tmp = _.cloneDeep(BrandData || []);
      console.log('NEW : Brand', tmp);
      dispatch({ type: 'SET_BRANDS', payload: tmp });
    }
  }, [BrandData]);

  useEffect(() => {
    console.log('NEW : product updated', products);
    if (products.length > 0) {
      let tmp = _.cloneDeep(products || []);
      dispatch({ type: 'SET_PRODUCTS', payload: tmp });
    }
  }, [products]);

  // Funksjon for å generere en unik nøkkel for hvert produkt
  const generateProductKey = (product: IImportProductAddViewModel) => {
    return `${product.name}-${product.bindingPeriod}-${product.paymentCycle}`;
  };

  // ON SUBMIT
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!company?.id) return;
    let ProductsList: IImportProductListViewModel = {
      products: [] as IImportProductAddViewModel[],
    };
    state.selectedProducts.forEach((productKey) => {
      let product = state.products.find(
        (p) => generateProductKey(p) === productKey
      );
      if (product) {
        let newProduct: IImportProductAddViewModel = {
          name: product.name,
          companyId: company.id || '',
          comment: product.comment,
          serialNumber: product.serialNumber,
          unitPrice: product.unitPrice,
          retailPrice: product.retailPrice,
          security: product.security,
          fileModel: product.fileModel,
          tempType: product.tempType,
          imageFile: product.imageFile,
          productTypeId:
            state.productTypeMapping[productKey] || product.productTypeId || '',
          EAN: product.EAN,
          brandId: state.brandTypeMapping[productKey] || product.brandId || '',
          quantity: product.quantity,
          paymentCycle: product.paymentCycle,
          cycleAlignment: product.cycleAlignment,
          bindingPeriod: product.bindingPeriod,
          productFeatureDataIds: product.productFeatureDataIds,
          legacyId: product.legacyId,
          thirdPartyId: product.thirdPartyId,
          thirdParty: product.thirdParty,
          purchaserId: user.id,
          productVariant: product.productVariant ?? 0,
        };
        ProductsList.products.push(newProduct);
      }
    });
    console.log('NEW : ProductsList', ProductsList);
    postProducts.mutate(ProductsList, {
      onSuccess: (res: any) => {
        if (res) {
          handleRemoveProducts(res.products as IProductViewModel[]);
          // dispatch({ type: 'RESET_FORM' });
        }
      },
      onError: (error: any) => {},
    });
  };

  console.log('x:: state', state);

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/* HEADER */}
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('Products.ProductForm.productDetails')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
          gfx={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Stack direction='column' spacing={0} sx={{ pr: 4 }}>
                <Typography variant='caption' color='primary.text'>
                  {t('Settings.Brand.header')}
                </Typography>
                <Select                  
                  size='small'
                  variant='standard'
                  value=''
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ALL_BRANDS',
                      payload: e.target.value as string,
                    })
                  }
                  displayEmpty
                  sx={{ minWidth: 170, color: 'white' }}
                >
                  {state.brands &&
                    state.brands.map((Brand) => (
                      <MenuItem key={Brand.id} value={Brand.id}>
                        {Brand.brandName}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Stack direction='column' spacing={0} sx={{ pr: 2 }}>
                <Typography variant='caption' color='primary.text'>
                  {t('Products.ProductForm.productType')}
                </Typography>
                <Select
                  size='small'
                  variant='standard'
                  value=''
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ALL_PRODUCT_TYPES',
                      payload: e.target.value as string,
                    })
                  }
                  displayEmpty
                  sx={{ minWidth: 170, color: 'white' }}
                >
                  {state.productType &&
                    state.productType.map((ProductsType) => (
                      <MenuItem key={ProductsType.id} value={ProductsType.id}>
                        {ProductsType.name}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Checkbox
                sx={{ pr: 4 }}
                checked={
                  state.selectedProducts.length === state.products.length
                }
                color={
                  state.selectedProducts.length === state.products.length
                    ? 'secondary'
                    : 'primary'
                }
                onChange={() =>
                  dispatch({
                    type: 'TOGGLE_ALL_PRODUCT_SELECTION',
                  })
                }
              />
            </Box>
          }
        />
      </Grid>

      <List
        sx={{
          width: '100%',
          height: listHeight,
          p: 1,
          borderRadius: 0,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        {state.products?.length > 0 &&
          state.productType.length > 0 &&
          state.brands.length > 0 &&
          state.products.map(
            (product: IImportProductAddViewModel, index: number) => {
              const productKey = generateProductKey(product);
              return (
                <ListItem
                  key={productKey}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Box sx={{ flex: 1 }}>
                    <ListItemText
                      primary={product.name}
                      secondary={`${product.paymentCycle} / ${product.bindingPeriod}  (x${product.quantity}) `}
                      primaryTypographyProps={{ color: 'white' }}
                      secondaryTypographyProps={{ color: 'white' }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mx: 2,
                    }}
                  >
                    <Stack direction='column' spacing={0}>
                      <Typography variant='caption' color='primary.text'>
                        {t('Settings.Brand.header')}
                      </Typography>
                      <Select
                        size='small'
                        variant='standard'
                        value={
                          state.brandTypeMapping[productKey] ||
                          product.brandId ||
                          ''
                        }
                        onChange={(e) =>
                          dispatch({
                            type: 'UPDATE_PRODUCT_BRAND',
                            payload: {
                              key: productKey,
                              brandId: e.target.value as string,
                            },
                          })
                        }
                        displayEmpty
                        sx={{ minWidth: 170, color: 'white' }}
                      >
                        {state.brands &&
                          state.brands.map((Brand) => (
                            <MenuItem key={Brand.id} value={Brand.id}>
                              {Brand.brandName}
                            </MenuItem>
                          ))}
                      </Select>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mx: 2,
                    }}
                  >
                    <Stack direction='column' spacing={0}>
                      <Typography variant='caption' color='primary.text'>
                        {t('Products.ProductForm.productType')}
                      </Typography>
                      <Select
                        size='small'
                        variant='standard'
                        value={
                          state.productTypeMapping[productKey] ||
                          product.productTypeId ||
                          ''
                        }
                        onChange={(e) =>
                          dispatch({
                            type: 'UPDATE_PRODUCT_TYPE',
                            payload: {
                              key: productKey,
                              productTypeId: e.target.value as string,
                            },
                          })
                        }
                        displayEmpty
                        sx={{ minWidth: 170, color: 'white' }}
                      >
                        {state.productType &&
                          state.productType.map((ProductsType) => (
                            <MenuItem
                              key={ProductsType.id}
                              value={ProductsType.id}
                            >
                              {ProductsType.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Stack>
                  </Box>
                  <Checkbox
                    checked={state.selectedProducts.includes(productKey)}
                    color={
                      state.selectedProducts.includes(productKey)
                        ? 'secondary'
                        : 'primary'
                    }
                    onChange={() =>
                      dispatch({
                        type: 'TOGGLE_PRODUCT_SELECTION',
                        payload: productKey,
                      })
                    }
                  />
                </ListItem>
              );
            }
          )}
      </List>
      {/*   Submit Button */}

      <Grid
        item
        xs={12}
        sx={{
          m: 0,
          p: 1,
          height: 64,
          display: 'flex',
          flexDirection: 'row-reverse',
          backgroundColor: 'primary.main',
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Button
          onClick={(e) => {
            onSubmit(e);
          }}
          variant='contained'
        >
          {postProducts.isLoading ? t('button.creating') : t('button.create')}
        </Button>
      </Grid>
    </Grid>
  );
};
