import {
  ITicketPostCommunicationData,
  ITicketPostAddViewModel,
} from 'Interfaces/ITicketConfig';
import { ConvertToAttachment } from './ConvertToAttachment';
import { GetWorkRole } from './GetWorkRole';
import dayjs from 'dayjs';

export const ConvertToTicketPost = (
  state: ITicketPostCommunicationData,
  user: any
) => {
  console.log('ticketpost: state', state);

  let _ticketPost: ITicketPostAddViewModel = {
    ticketId: state.ticket.id,
    status: state.status,
    startTime: dayjs(state.startDate).format(), // Send local time, no UTC conversion
    endTime: dayjs(state.endDate).format(), // Send local time, no UTC conversion
    startDeduction: null, // Send local time
    endDeduction: null, // Send local time
    billable: state.isBillable,
    overTime: state.overTime,
    isBilled: false,
    internal: state.internal,
    isInHouse: state.isInHouse,
    isEmailSendt: state.isEMailSent,
    direction: 1,
    authorId: user.id,
    receiverId: state.ticket?.contact.id,
    message: state.comment || null,
    attachments: state.attachments
      ? ConvertToAttachment(state.attachments)
      : null,
    workRoleId: state.workRole?.id ? state.workRole?.id : null,
    ticketPostBilling: state.ticketPostBilling,
  };
  console.log('GetWorkRole: _ticketPost', _ticketPost);

  return _ticketPost;
};
